import { format } from 'date-fns'
export default {
  methods: {
    dateFormart(str) {
      if (!str) return ''
      const date = Date.parse(str)
      return format(date, 'yyyy.MM.dd')
    },
  },
}
